<template>
  <div
    id="AuthLogin"
    class="page-login d-flex align-items-center justify-content-center"
  >
    <div class="container">
      <div class="main-card">
        <div class="row" style="height: 700px">
          <div class="col-6 img-login">
            <div class="position-relative h-100">
              <img src="/images/DD0fKMPg8ZA.jpg" class="img-bg-admin" />

              <div class="iconify">
                <iconify
                  icon="ant-design:caret-left-outlined"
                  class="font-white"
                />
              </div>
            </div>
          </div>
          <div class="col-6 input-login">
            <div class="d-flex flex-column px-5 pt-5 pb-5 h-100">
              <div class="text-center">
                <img src="/images/logo.png" />
              </div>
              <div
                class="
                  text-center
                  fontsize-h3
                  font-headblue
                  weight-700
                  mb-5
                  mt-5
                "
              >
                สำหรับเจ้าหน้าที่ <br />ระบบประเมินคุณภาพประปาหมู่บ้าน
              </div>

              <div class="flex-fill">
                <b-form
                  @submit="handleLogin"
                  style="height: 100%"
                  class="d-flex flex-column"
                >
                  <div class="flex-fill">
                    <div
                      class="mb-5 position-relative"
                      :class="{
                        'login-input-focus': isUsernameFocus,
                        'login-input-req': submit && $v.username.$invalid,
                      }"
                    >
                      <label for="user" class="label-top form-label weight-700">
                        บัญชีผู้ใช้
                      </label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">
                            <img src="/images/user.png" />
                          </span>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="username"
                          aria-describedby="basic-addon1"
                          placeholder="Username"
                          @focus="isUsernameFocus = true"
                          @blur="isUsernameFocus = false"
                        />
                      </div>
                    </div>

                    <div
                      class="mb-3 position-relative"
                      :class="{
                        'login-input-focus': isPasswordFocus,
                        'login-input-req': submit && $v.password.$invalid,
                      }"
                    >
                      <label
                        for="password"
                        class="label-top form-label weight-700"
                        >รหัสผ่าน</label
                      >
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon2">
                            <img src="/images/unlock.png" />
                          </span>
                        </div>
                        <input
                          type="password"
                          class="form-control"
                          v-model="password"
                          aria-describedby="basic-addon2"
                          placeholder="Password"
                          @focus="isPasswordFocus = true"
                          @blur="isPasswordFocus = false"
                        />
                      </div>
                    </div>
                    <div class="mb-3">
                      <b-form-checkbox v-model="isRemember">
                        <span class="font-headblue">จำไว้เสมอ</span>
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div>
                    <div
                      class="text--error mb-3"
                      v-show="isLoginFail && submit"
                    >
                      บัญชีผู้ใช้หรือรหัสผ่านไม่ถูกต้อง
                    </div>
                    <div>
                      <button type="submit" class="btn btn-submit w-100">
                        เข้าสู่ระบบ
                      </button>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { UserService } from "@/services/main.service.js";

export default {
  name: "auth-admin",
  data() {
    return {
      username: null,
      password: null,
      isRemember: false,
      isLoginFail: false,
      dwrremember: null,
      isUsernameFocus: false,
      isPasswordFocus: false,
      submit: false,
    };
  },
  validations: {
    username: {
      required,
    },
    password: {
      required,
    },
  },
  created() {
    window._login = this;

    this.getRemember();
  },
  methods: {
    handleLogin(event) {
      event.preventDefault();

      this.submit = true;

      if (this.$v.username.$invalid || this.$v.password.$invalid) {
        return;
      }

      const params = {
        username: this.username,
        password: this.password,
      };

      this.$store
        .dispatch("account/login", params)
        .then(async (res) => {
          if (res.status) {
            //remember me
            this.isRemember ? this.setRemember() : this.removeRemember();

            this.isLoginFail = false;
            this.$store.commit("account/saveLocalToken");
          } else {
            this.isLoginFail = true;
          }

          return {
            status: res.status,
            userId: res.userId || null,
          };
        })
        .then(async (res) => {
          // getProfile
          if (res.status) {
            const userId = res.userId;
            await UserService.getProfile(userId)
              .then((res) => {
                const data = { ...res, password: null };
                this.$store.commit("account/setProfile", data);
              })
              .catch((error) => {
                this.$store.commit("account/setProfile", {});
              });
          }

          this.$router.push({ name: "MenuPage" });
        })
        .catch((error) => {
          this.isLoginFail = true;
        });
    },
    getRemember() {
      // Retrieve the object from storage
      const dwrremember = localStorage.getItem("dwrremember");

      this.dwrremember = JSON.parse(dwrremember);
    },
    setRemember() {
      localStorage.setItem(
        "dwrremember",
        JSON.stringify({
          remember: true,
          username: this.username,
        })
      );
    },
    removeRemember() {
      localStorage.removeItem("dwrremember");
    },
  },
  watch: {
    dwrremember: {
      handler: function (val, oldVal) {
        if (val) {
          this.isRemember = val.remember;
          this.username = val.remember ? val.username || null : null;
        } else {
          this.isRemember = false;
          this.username = null;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss"></style>
